import './App.scss';
import NewsletterSubscribe from '../NewsletterForm/NewsletterSubscribe';
import data from '../../data/english.json';

function App() {
    return (
        <div className="App-header">
            <img src={require('../../assets/images/logo.png')} alt="Logo" className="logo" />
            <div className="content">
                <h1>{data.ourWebsite}</h1>
                <p>{data.stayTuned}</p>
            </div>
            <NewsletterSubscribe />
            <div className="logos-container">
                <p>{data.supportedBy}</p>
                <div className="logos">
                    <img src={require('../../assets/images/uzh.png')} alt="Uzh Logo" />
                    <img src={require('../../assets/images/Innosuisse.png')} alt="Innosuisse Logo" />
                    <img src={require('../../assets/images/startup-campus.png')} alt="Startup Campus Logo" />
                </div>
            </div>
            <div className="pattern" />
        </div>
    );
}

export default App;
