import { useState, useEffect } from 'react';
import { decode } from 'html-entities';
import data from '../../data/english.json';
import './NewsletterForm.scss';

const NewsletterForm = ({ status, message, onValidated }) => {
    const [error, setError] = useState(null);
    const [email, setEmail] = useState('');
    const [type, setType] = useState(null);

    const [currentMessage, setCurrentMessage] = useState(message);

    useEffect(() => {
        setCurrentMessage(message);
    }, [message]);

    /**
     * Handle form submit.
     *
     * @return {{value}|*|boolean|null}
     */
    const handleFormSubmit = () => {
        setError(null);

        if (!email) {
            setError(data.form.errors.validEmailAddress);
            return null;
        }

        if (!type) {
            setError(data.form.errors.type);
            return null;
        }

        /* tag 32309 for interested */
        const isFormValidated = onValidated({ EMAIL: email, TYPE: type, tags: '32309' });

        // On success return true
        setEmail('');
        setType('');
        setTimeout(() => setError(''), 1000);
        return email && email.indexOf('@') > -1 && isFormValidated;
    };

    /**
     * Handle Input Key Event.
     *
     * @param event
     */
    const handleInputKeyEvent = (event) => {
        setError(null);
        setCurrentMessage('');
        // Number 13 is the "Enter" key on the keyboard
        if (event.keyCode === 13) {
            // Cancel the default action, if needed
            event.preventDefault();
            // Trigger the button element with a click
            handleFormSubmit();
        }
    };

    /**
     * Extract message from string.
     *
     * @param {String} message
     * @return {null|*}
     */
    const getMessage = (message) => {
        if (!message) {
            return null;
        }
        const result = message?.split('-') ?? null;
        if ('0' !== result?.[0]?.trim()) {
            return decode(message);
        }
        const formattedMessage = result?.[1]?.trim() ?? null;
        return formattedMessage ? decode(formattedMessage) : null;
    };

    /**
     * Select type of person interested on glimpse
     *
     * @param {String} message
     * @return {null|*}
     */
    const selectType = (type) => {
        setError(null);
        setCurrentMessage('');
        setType(type);
    };

    return (
        <>
            <div className="newsletter-form">
                <div className="type-group">
                    <div className="im-a">{data.form.fields.im}</div>
                    <div className="options">
                        <div className={`option`} onClick={() => selectType('Medical')}>
                            <div className={`radio-button ${type === 'Medical' ? 'active' : ''} `} />
                            <label>{data.form.fields.medical}</label>
                        </div>
                        <div className={`option`} onClick={() => selectType('Patient')}>
                            <div className={`radio-button ${type === 'Patient' ? 'active' : ''} `} />
                            <label>{data.form.fields.patient}</label>
                        </div>
                        <div className={`option`} onClick={() => selectType('Just Curious')}>
                            <div className={`radio-button ${type === 'Just Curious' ? 'active' : ''} `} />
                            <label>{data.form.fields.curious}</label>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="box">
                        <input
                            name="email"
                            onChange={(event) => setEmail(event?.target?.value ?? '')}
                            type="email"
                            placeholder={data.form.fields.email}
                            onKeyUp={(event) => handleInputKeyEvent(event)}
                            value={email}
                        />
                    </div>
                    <button className="submit-button" onClick={handleFormSubmit}>
                        {data.form.fields.button}
                    </button>
                </div>

                <div className="newsletter-form-info">
                    {status === 'sending' && <div className="newsletter-form-error">{data.form.messages.sending}.</div>}
                    {status === 'error' || error ? (
                        <div
                            className="newsletter-form-error"
                            dangerouslySetInnerHTML={{ __html: error || getMessage(currentMessage) }}
                        />
                    ) : null}
                    {status === 'success' && status !== 'error' && !error && (
                        <div dangerouslySetInnerHTML={{ __html: decode(currentMessage) }} />
                    )}
                </div>
            </div>
        </>
    );
};

export default NewsletterForm;
